* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #f1f5f8;
  color: #222;
}

.catlist {
  width: 90vw;
  max-width: 1170px;
  margin: 5rem auto;
  display: grid;
  gap: 2rem;
}
@media screen and (min-width: 798px) {
  .catlist {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cat {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  position: relative;
}

.cat h2 {
  margin-top: 1rem;
  font-size: 1rem;
}

.cat img {
  width: 100%;
  object-fit: cover;
}

.cat h4 {
  letter-spacing: 2px;
  color: cadetblue;
}

.number {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1rem;
  padding: 0.75rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background: orange;
  color: white;
}

.page-title {
  display: flex;
  justify-content: center;
  font-size: 5rem;
  font-weight: 500;
  color: orange;
  text-align: center;
  margin-top: 5rem;
  text-shadow: 0.25rem 0.25rem white;
}
